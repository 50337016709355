/* src/index.css */

:root {
  --primary-dark: #3d5a80;
  --primary-light: #98c1d9;
  --accent-white: #e0fbfc;
  --accent-orange: #ee6c4d;
  --dark-navy: #293241;
}

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  display: flex;
  /* Ensure full vertical height */
}

.navbar {
  z-index: 1050;
  /* Higher than most other items on the page */
  position: relative;
  /* Change to 'fixed' if you want it to stay at the top when scrolling */
}

.main-content {
  flex-grow: 1;
  /* Takes up the remaining space */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 20px;
  /* Optional: Adds some spacing inside the main content */
  padding-top: 20px;
}

.fixed-pagination {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  text-align: center;
}

.tagline-style {
  font-family: 'Ubuntu', sans-serif;
  /* Keeps the style similar but makes it distinct */
  font-size: 0.95rem;
  /* Slightly smaller for a tagline */
  font-weight: 600;
  /* Semi-bold to create emphasis */
  color: black;
  /* Complementary color */
  letter-spacing: 0.5px;
  /* Adds a slight spacing for a cleaner look */
  line-height: 1.2;
  /* Adjusts line height for readability */
  font-style: normal;
  /* Regular style, could use 'italic' if needed */
}