/* src/custom.css */
/* This is the custom CSS file for your Saarthi UI project. */
:root {
    /* Original colors - kept for backward compatibility */
    --primary-dark: #3d5a80;
    --primary-light: #98c1d9;
    --accent-white: #e0fbfc;
    --accent-orange: #ee6c4d;
    --dark-navy: #293241;
    
    /* New modern AI color palette */
    --ai-dark: #0F0F1A;
    --ai-purple: #6E11F4;
    --ai-blue: #1E38F7;
    --ai-teal: #00C9E0;
    --ai-light: #F5F5FF;
    --ai-neon: #00F0B5;
    --ai-gradient-1: linear-gradient(135deg, var(--ai-purple), var(--ai-blue));
    --ai-gradient-2: linear-gradient(135deg, var(--ai-blue), var(--ai-teal));
    --ai-glass: rgba(255, 255, 255, 0.1);
    --ai-glass-dark: rgba(15, 15, 26, 0.7);
}
h2 {
    color: var(--primary-dark); /* Change this to your desired color */
}

p {
    color: var(--dark-navy); /* Change this to your desired color */
    font-size: 1.2rem;
}

.fixed-width-label {
    min-width: 150px;
    /* Adjust this value based on your design needs */
    border: 1px solid #ced4da;
    /* Adjust the color as per your theme */
    padding: 0.375rem 0.75rem;
    /* Standard padding */
    background-color: #e9ecef;
    /* Standard background */
}

.switch-container {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 0.75rem;
    flex: 1;
}

.right-rounded-button {
    border-top-left-radius: 0 !important;
    /* Ensures the top left corner is square */
    border-bottom-left-radius: 0 !important;
    /* Ensures the bottom left corner is square */
}


/* Clipping the image from the left side */
.trapezoid-img-left {
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

/* Clipping the image from the right side */
.trapezoid-img-right {
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
}

/* Clipping the image from the bottom left */
.trapezoid-img-bottom-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 16% 100%);
}

/* Clipping the image from the bottom right */
.trapezoid-img-bottom-right {
    clip-path: polygon(0 10%, 100% 10%, 75% 85%, 0% 85%);
    margin-top: -50px;
    margin-bottom: -50px
}


.home-banner {
    min-height: 300px;
    /* Changes from fixed height to minimum height */
    background-size: contain !important;
    background-position: top center !important; /* Moves the image to the top */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Ensure it's relative if using z-index elsewhere */
}

@media (max-width: 768px) {
    .home-banner {
        min-height: 80vh; /* Ensures enough height */
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 120px !important; /* Push content below the navbar */
        padding-bottom: 20px; /* Avoid cutting off the bottom */
        background-size: contain !important;
        background-position: center top !important;
    }
}


.home-banner img {
    width: 100%;
    /* Make the image fill the column */
    height: 460px;
    /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image maintains aspect ratio */
}

@media (max-width: 768px) {
    .home-banner img {
        display: none;
    }
}

.home-banner .content-style {
    max-height: 300px;
    overflow: hidden;
}

@media (max-width: 768px) {
    .home-banner .content-style {
        padding-top: 60px; /* Push text further down */
        text-align: center;
    }
}

/* Wrap container for hover effect */
.arama-container {
    display: inline-block;
    position: relative;
}

/* Position full form above "ARAMA" */
.arama-full-form {
    display: none;
    position: absolute;
    bottom: 100%; /* Moves it above "ARAMA" */
    left: 50%;
    transform: translateX(-50%); /* Centers it */
    white-space: nowrap; /* Prevents line breaks */
    font-size: 14px;
    font-weight: bold;
    color: #4B88A2;
    background: white; /* Ensures readability */
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Show the full form on hover */
.arama-hover:hover + .arama-full-form {
    display: inline-block;
}

/* Hide the SVG on hover */
.arama-hover:hover ~ svg {
    display: none;
}

/* Styling for statistic boxes */
.stats-box-style {
    background-color: #FCDEBE;
    /* Soft blue background */
    color: #252627;
    /* Dark charcoal text color */
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    text-align: center;
}

/* Note styling specifically for the customer feedback note */
.note-style {
    text-align: left;
    /* Align text to the left */
    font-size: 14px;
    color: #555;
    font-style: italic;
    margin-top: 20px;
    margin-left: 20px;
    /* Optional: add some spacing from the left side */
}

/* Styling for numbers in statistic boxes */
.number-style {
    font-size: 2rem;
    font-weight: bold;
    color: #252627;
    /* Bold red for numbers to attract attention */
}

/* Description text styling in statistic boxes */
.description-style {
    font-size: 1.1rem;
    color: #252627;
    /* Light green for descriptions to complement the red */
}


/* Ensures that each section has enough space around it */
.container-fluid {
    margin-top: 5px;
    /* Adds space above each container */
    margin-bottom: 10px;
    /* Adds space below each container */
}

/* Additional padding within each container can also help */
.container-fluid>.row {
    padding-top: 5px;
    padding-bottom: 10px;
}

@media (min-width: 992px) {

    /* Adjusts for large screens */
    .container-fluid {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .container-fluid>.row {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.banner-text {
    text-align: left;
    /* Centers the text and button horizontally */
}

.banner-text h1,
.banner-text p {
    margin: 0;
    padding: 10px;
}

.sub-text {
    font-size: 1.4rem;
    /* Adjust size as needed */
}

.icon-image {
    width: 100px;
    /* Adjust this value to set the width */
    height: auto;
    /* This keeps the aspect ratio intact */
}

.blockquote {
    font-size: 1.00rem;
    /* Larger font size */
    font-style: italic;
    /* Italicize the quote */
    color: #555;
    /* Dark gray for slight emphasis */
}

.bordered-title {
    border-top: 1px solid #ccc;
    /* Light grey border on top */
    border-bottom: 1px solid #ccc;
    /* Light grey border on bottom */
    padding-top: 15px;
    /* Space between top border and text */
    padding-bottom: 10px;
    /* Space between text and bottom border */
}


.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /* Adds space between items */
}

.feature-item i {
    font-size: 2rem;
    /* Icon size */
    margin-right: 20px;
    /* Space between the icon and the text */
}

/* Values section styling */
.value-icon {
    font-size: 2rem;
    margin-right: 20px;
    vertical-align: middle;
    color: #4B88A2;
    /* Custom color for icons */
}

.value-content h4 {
    margin-bottom: 5px;
    color: #333;
    /* Dark color for headings */
}

.value-content p {
    color: #666;
    /* Lighter color for text */
    margin-bottom: 10px;
}

.custom-button:hover {
    transform: scale(1.05);
    /* Slightly increase size on hover */
    background-color: '#FF6347';
    /* Lighter shade when hovered */
}

.custom-button-portal {
    width: 120px;
    height: 35px;
    border: none;
    /* Assuming you want to keep no borders as per your initial setup */
    color: white;
    border-radius: 0.25rem;
    /* Bootstrap default border-radius, adjust as needed */
}

.custom-button-portal:hover {
    transform: scale(1.05);
    /* Slightly increase size on hover */
}


.table-responsive {
    max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide horizontal scroll if not needed */
    display: block;
    /* Wrap table in a block container to allow for scrolling */
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
    /* This helps in maintaining uniform column widths */
}

.table thead th {
    position: sticky;
    /* Makes the header sticky */
    top: 0;
    /* Keeps the header at the top */
    background-color: #252627;
    /* Ensures the header is not transparent */
    color: #fff;
    /* White text color */
    z-index: 10;
    /* Keeps the header above the tbody content */
}

.table tbody tr {
    display: table-row;
    /* Default display for table rows */
    width: 100%;
    table-layout: fixed;
    /* Ensures table layout is maintained */
}


.blurry-overlay {
    position: relative;
    filter: blur(5px);
    pointer-events: none;
}

.overlay-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 1;
}

.overlay-container {
    position: relative;
}

.hidden-content {
    pointer-events: none;
}


.hidden-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none;
    /* For Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

/* Modern AI Theme Styling */
.modern-ai-theme {
  overflow-x: hidden;
  position: relative;
}

.main-heading {
  color: #E0FBFC;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Gradient Text */
.gradient-text {
  background: linear-gradient(90deg, var(--primary-dark) 0%, #4B88A2 50%, var(--accent-orange) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Section Divider */
.section-divider {
  position: relative;
}

.section-divider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(75, 136, 162, 0.5), transparent);
}

/* Modern Paragraphs */
.modern-paragraph {
  line-height: 1.8;
  font-size: 1.1rem;
  color: #ffffff;
}

/* Section Headings */
.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #0F0F1A;
}

/* Title Underline */
.section-title-container {
  margin-bottom: 1.5rem;
  position: relative;
}

.title-underline {
  height: 4px;
  width: 80px;
  background: linear-gradient(90deg, var(--primary-dark), var(--accent-orange));
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Gradient Links */
.gradient-link {
  background: linear-gradient(90deg, var(--primary-dark) 0%, var(--accent-orange) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.gradient-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-dark) 0%, var(--accent-orange) 100%);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.gradient-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Gradient Buttons */
.gradient-button {
  background: linear-gradient(45deg, var(--primary-dark), #4B88A2) !important;
  border: none !important;
  border-radius: 30px !important;
  transition: all 0.3s ease !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.gradient-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #4B88A2, var(--accent-orange));
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.gradient-button:hover::before {
  opacity: 1;
}

/* Floating Image Container */
.floating-image-container {
  position: relative;
  overflow: visible;
  margin: 20px;
}

.modern-image {
  border-radius: 12px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
  position: relative;
  z-index: 2;
}

.gradient-overlay {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(135deg, rgba(75, 136, 162, 0.2), rgba(187, 68, 48, 0.2));
  filter: blur(20px);
  border-radius: 16px;
  z-index: 1;
  opacity: 0.8;
}

/* Floating Dots Animation */
.floating-dots {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-dark);
  z-index: 3;
}

.dot1 {
  top: 20%;
  right: -15px;
  animation: float 3s ease-in-out infinite;
}

.dot2 {
  bottom: 30%;
  left: -15px;
  animation: float 4s ease-in-out infinite 1s;
  background: var(--accent-orange);
}

.dot3 {
  top: -15px;
  left: 30%;
  animation: float 5s ease-in-out infinite 0.5s;
}

/* Floating Circuit Animation */
.floating-circuit {
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 1;
  opacity: 0.6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' fill='none' stroke='%234B88A2' stroke-width='1'%3E%3Cpath d='M20,20 L80,20 L80,80 L20,80 Z'/%3E%3Cpath d='M30,30 L70,30 M30,50 L70,50 M30,70 L70,70'/%3E%3Cpath d='M30,30 L30,70 M50,30 L50,70 M70,30 L70,70'/%3E%3C/svg%3E");
  background-size: contain;
}

.circuit1 {
  top: -40px;
  right: 10%;
  animation: rotate 20s linear infinite;
}

.circuit2 {
  bottom: -30px;
  left: 10%;
  animation: rotate 15s linear infinite reverse;
}

/* Pulse Effect */
.pulse-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgba(75, 136, 162, 0.2);
  z-index: 0;
  animation: pulse 2s infinite;
}

/* Modern List Styling */
.modern-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modern-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #252627;
}

.list-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, var(--primary-dark), #4B88A2);
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.list-icon-container i {
  color: white;
}

/* Modern Modal */
.modern-modal .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.modern-modal .modal-header {
  border-bottom: 1px solid rgba(75, 136, 162, 0.2);
  background: linear-gradient(to right, rgba(75, 136, 162, 0.05), transparent);
}

/* Hero Section */
.hero-section {
  padding: 80px 0;
  position: relative;
}

/* Features Section */
.features-section {
  position: relative;
}

/* FAQ Section */
.faq-section {
  position: relative;
}

/* Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.7;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .section-heading {
    font-size: 2rem;
  }
  
  .modern-paragraph {
    font-size: 1rem;
  }
  
  .floating-image-container {
    margin: 10px;
  }
}

/* Features Component Styling */
.features-container {
  position: relative;
}

.feature-image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 500px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.feature-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.feature-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(75, 136, 162, 0.2), rgba(187, 68, 48, 0.1));
  border-radius: 16px;
  pointer-events: none;
}

.data-points {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.data-point {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(75, 136, 162, 0.8);
  animation: pulse-data 2s infinite;
}

.point-1 { top: 20%; left: 15%; animation-delay: 0s; }
.point-2 { top: 70%; left: 25%; animation-delay: 0.3s; }
.point-3 { top: 40%; left: 80%; animation-delay: 0.6s; }
.point-4 { top: 80%; left: 65%; animation-delay: 0.9s; }
.point-5 { top: 30%; left: 40%; animation-delay: 1.2s; }
.point-6 { top: 60%; left: 50%; animation-delay: 1.5s; }
.point-7 { top: 10%; left: 75%; animation-delay: 1.8s; }
.point-8 { top: 50%; left: 10%; animation-delay: 2.1s; }

.features-tab-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feature-tab {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-tab.active-feature {
  background: linear-gradient(90deg, rgba(75, 136, 162, 0.1), rgba(255, 255, 255, 0.95));
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(75, 136, 162, 0.15);
  border-left: 5px solid var(--primary-dark);
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, var(--primary-dark), #4B88A2);
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.feature-icon i {
  font-size: 24px;
  color: white;
}

.feature-content {
  flex: 1;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: #252627;
}

.feature-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}

.feature-indicator {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  transition: all 0.3s ease;
}

.feature-indicator.active {
  background-color: var(--accent-orange);
  box-shadow: 0 0 10px rgba(187, 68, 48, 0.5);
}

@keyframes pulse-data {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

/* Modern FAQ Accordion Styling */
.modern-faq-container {
  margin-top: 20px;
}

.modern-accordion {
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 12px;
  --bs-accordion-inner-border-radius: 12px;
}

.modern-accordion-item {
  margin-bottom: 16px;
  border-radius: 12px !important;
  overflow: hidden;
  border: none !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.modern-accordion-item:hover {
  box-shadow: 0 8px 25px rgba(75, 136, 162, 0.2);
  transform: translateY(-2px);
}

.modern-accordion-header button {
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.modern-accordion-header button:not(.collapsed) {
  color: var(--primary-dark) !important;
  background: linear-gradient(90deg, rgba(75, 136, 162, 0.1), transparent) !important;
  box-shadow: none !important;
}

.modern-accordion-header button:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.modern-accordion-body {
  padding: 5px 20px 20px 20px !important;
  color: #666;
  line-height: 1.6;
}

.faq-question-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.faq-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, var(--primary-dark), #4B88A2);
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.faq-icon i {
  color: white;
  font-size: 16px;
}

.faq-answer {
  position: relative;
  padding-left: 50px;
}

.faq-answer::before {
  content: '';
  position: absolute;
  left: 18px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, var(--primary-dark), transparent);
}

/* Modern Footer Styling */
.modern-footer {
  position: relative;
  padding: 40px 0 20px;
  background: linear-gradient(135deg, #252627 0%, #4B5053 100%);
  color: white;
  overflow: hidden;
  margin-top: 80px;
}

.footer-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 35%, rgba(75, 136, 162, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 75% 75%, rgba(187, 68, 48, 0.1) 0%, transparent 50%);
  opacity: 0.8;
  z-index: 0;
}

.modern-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, var(--primary-dark), var(--accent-orange));
}

.footer-brand {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.footer-logo-container {
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.footer-logo {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
}

.logo-glow-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.logo-glow {
  position: absolute;
  width: 10%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  filter: blur(3px);
  z-index: 0;
}

.logo-image {
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 2px 2px 2px 4px;
  box-shadow: 0 0 15px rgba(0, 201, 224, 0.7);
}

.footer-brand-text {
  display: flex;
  flex-direction: column;
}

.footer-title {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  background: linear-gradient(90deg, #ffffff, #AEC15F);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.footer-tagline {
  font-size: 14px;
  margin: 0;
  opacity: 0.8;
}

.footer-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.footer-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 3px;
  background: linear-gradient(90deg, var(--primary-dark), var(--accent-orange));
  border-radius: 2px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
}

.footer-link::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-dark), var(--accent-orange));
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: white;
  transform: translateX(5px);
}

.footer-link:hover::after {
  width: 100%;
}

.footer-socials {
  display: flex;
  gap: 15px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.social-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--primary-dark), var(--accent-orange));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.social-link:hover {
  transform: translateY(-5px);
  color: white;
}

.social-link:hover::before {
  opacity: 1;
}

.footer-contact {
  position: relative;
  z-index: 1;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-item i {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-right: 10px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
}

.copyright {
  font-size: 14px;
  opacity: 0.7;
}

.footer-legal {
  display: flex;
  gap: 20px;
}

.legal-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.legal-link:hover {
  color: white;
}

.modern-tooltip .tooltip-inner {
  background-color: rgba(75, 136, 162, 0.95);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  max-width: 300px;
}

.modern-tooltip .arrow::before {
  border-right-color: rgba(75, 136, 162, 0.95) !important;
}

@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
  
  .footer-heading {
    margin-top: 20px;
  }
}

/* About Us Page Styling */
.about-us-page {
  overflow-x: hidden;
}

.value-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}

.value-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(75, 136, 162, 0.15);
  background: linear-gradient(90deg, rgba(75, 136, 162, 0.1), rgba(255, 255, 255, 0.9));
}

.value-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, var(--primary-dark), #4B88A2);
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.value-icon-container i {
  font-size: 24px;
  color: white;
}

.value-content {
  flex: 1;
}

.value-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: #252627;
}

.highlight-letter {
  color: var(--accent-orange);
  font-weight: 800;
  text-decoration: underline;
}

.content-card {
  padding: 30px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-left: 5px solid var(--primary-dark);
  backdrop-filter: blur(10px);
  height: 100%;
}

.contact-section {
  position: relative;
  background: linear-gradient(to right, rgba(75, 136, 162, 0.1), rgba(255, 255, 255, 0.8), rgba(187, 68, 48, 0.05));
  border-radius: 16px;
  padding: 40px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.contact-intro {
  position: relative;
  padding-right: 20px;
}

.contact-decoration {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 5px;
  height: 70%;
  background: linear-gradient(to bottom, var(--primary-dark), var(--accent-orange));
  border-radius: 5px;
}

.contact-form-container {
  padding: 20px;
  position: relative;
  z-index: 2;
}

/* Contact Page Styling */
.contact-form-wrapper {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-left: 5px solid var(--primary-dark);
  height: 100%;
}

.contact-info-card {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  z-index: 5;
}

.contact-info-item {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 200px;
}

.contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--primary-dark), #4B88A2);
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.contact-icon i {
  font-size: 20px;
  color: white;
}

.contact-info-item h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 4px;
  color: #252627;
}

.contact-info-item p {
  font-size: 1rem;
  margin: 0;
  color: #666;
}

.contact-page-bottom {
  margin-top: 100px;
}

/* Modern Contact Form Styling */
.modern-contact-form {
  width: 100%;
}

.language-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.language-label {
  margin-right: 15px;
  font-weight: 500;
  color: #252627;
}

.select-wrapper {
  position: relative;
  width: 150px;
}

.modern-select {
  width: 100%;
  padding: 10px 35px 10px 15px;
  border-radius: 8px;
  border: 1px solid rgba(75, 136, 162, 0.3);
  background: rgba(255, 255, 255, 0.9);
  appearance: none;
  font-size: 14px;
  color: #252627;
  transition: all 0.3s ease;
  cursor: pointer;
}

.modern-select:focus {
  outline: none;
  border-color: var(--primary-dark);
  box-shadow: 0 0 0 3px rgba(75, 136, 162, 0.2);
}

.select-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--primary-dark);
}

.form-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.modern-iframe {
  border: none;
  background: transparent;
  transition: opacity 0.3s ease;
}

/* Global Typography Updates with Ubuntu Font */
body {
  font-family: 'Ubuntu', sans-serif;
  background-color: var(--ai-dark);
  color: var(--ai-light);
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 3.5rem;
  background: var(--ai-gradient-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 2.5rem;
  color: var(--ai-light);
  margin-bottom: 1.2rem;
}

p {
  font-family: 'Ubuntu', sans-serif;
  color: var(--ai-light);
  font-size: 1.1rem;
  line-height: 1.8;
}

/* Glass-morphism Components */
.glass-card {
  background: var(--ai-glass);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.glass-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
}

/* Modern Button Styles */
.ai-button {
  background: var(--ai-gradient-1);
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 6px 15px rgba(30, 56, 247, 0.3);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.ai-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--ai-gradient-2);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ai-button:hover::before {
  opacity: 1;
}

.ai-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(30, 56, 247, 0.4);
}

/* Data Visualization Containers */
.data-container {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background: var(--ai-glass-dark);
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-point {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--ai-neon);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Hero Section Styling */
.modern-hero {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  background: var(--ai-dark);
}

.hero-blob {
  position: absolute;
  top: -20%;
  right: -10%;
  width: 60%;
  height: 80%;
  background: var(--ai-gradient-1);
  filter: blur(120px);
  opacity: 0.3;
  z-index: 0;
  border-radius: 50%;
}

.hero-content {
  position: relative;
  z-index: 2;
}

/* Grid Lines Background */
.grid-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: 50px 50px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.03) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
  z-index: 1;
}

/* Features Section */
.modern-features {
  padding: 6rem 0;
  position: relative;
}

.feature-card {
  height: 100%;
  transition: all 0.3s ease;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: var(--ai-gradient-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Custom Animation Classes */
.fade-up {
  animation: fadeUp 0.8s ease forwards;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 3D Floating Elements */
.floating-3d {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

/* Navbar Styling */
.navbar {
  padding: 1rem 0;
  z-index: 1000;
}

.navbar-scrolled {
  padding: 0.5rem 0;
}

.nav-item {
  position: relative;
  color: var(--ai-light) !important;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background: var(--ai-teal);
  transition: width 0.3s ease;
}

.nav-item:hover::after {
  width: 100%;
}

.nav-item.active {
  color: var(--ai-teal) !important;
}

.nav-item.active::after {
  width: 100%;
}

.ai-button-sm {
  background: var(--ai-gradient-1);
  color: white !important;
  padding: 0.5rem 1.2rem !important;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(30, 56, 247, 0.3);
}

.ai-button-sm:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(30, 56, 247, 0.4);
  background: var(--ai-gradient-2);
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* Process Number Styling */
.process-number {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--ai-gradient-1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Stat Number Styling */
.stat-number {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: var(--ai-gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Visualization Containers */
.visualization-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.chart-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 20px 20px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  z-index: 0;
}

.active-feature {
  box-shadow: 0 10px 30px rgba(0, 201, 224, 0.15);
}

.feature-item {
  transition: all 0.3s ease;
}

/* Footer Styling */
footer {
  background: var(--ai-dark);
  color: var(--ai-light);
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.03) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
  background-size: 30px 30px;
  z-index: 0;
}

.footer-content {
  position: relative;
  z-index: 1;
}

.footer-link {
  color: var(--ai-light);
  text-decoration: none;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.footer-link:hover {
  color: var(--ai-teal);
  opacity: 1;
}

/* Modal Styling */
.modal-content {
  background: var(--ai-dark);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  color: var(--ai-light);
}

.modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.modal-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.btn-close {
  color: var(--ai-light);
  filter: invert(1) brightness(2);
}

/* Form Controls */
.form-control, .form-select {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--ai-light);
  border-radius: 8px;
}

.form-control:focus, .form-select:focus {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: var(--ai-teal);
  box-shadow: 0 0 0 0.25rem rgba(0, 201, 224, 0.25);
  color: var(--ai-light);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Media Queries */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  .modern-hero {
    padding-top: 7rem;
  }
  
  .data-container {
    min-height: 250px;
  }
}

/* Use Case Section Styling */
.use-case-card {
  padding: 2rem;
  height: 100%;
  border-left: none;
  position: relative;
  overflow: hidden;
}

.use-case-card:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background: var(--ai-gradient-1);
  opacity: 0.8;
}

.use-case-card:nth-of-type(2n):before {
  background: var(--ai-gradient-2);
}

.use-case-visual {
  background: rgba(15, 15, 26, 0.3);
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.use-case-benefits {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

@media (max-width: 992px) {
  .use-case-visual {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .order-lg-1 {
    order: 2;
  }
  
  .order-lg-2 {
    order: 1;
  }
}

/* Orbital bodies for 3-body problem visualization */
.orbital-body {
  position: absolute;
  border-radius: 50%;
  border: 1px dashed rgba(0, 201, 224, 0.4);
  transform-origin: center;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbital-body-1 {
  width: 80px;
  height: 65px; /* Make elliptical by reducing height */
}

.orbital-body-2 {
  width: 95px;
  height: 75px; /* Make elliptical by reducing height */
  border-color: rgba(110, 17, 244, 0.4);
}

.orbital-body-3 {
  width: 115px;
  height: 85px; /* Make elliptical by reducing height */
  border-color: rgba(30, 56, 247, 0.4);
}

.orbital-dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--ai-teal);
}

.orbital-body-1 .orbital-dot {
  top: 0;
  left: 60%; /* Adjust for elliptical path */
  transform: translateX(-50%);
  background-color: var(--ai-teal);
}

.orbital-body-2 .orbital-dot {
  bottom: 5px;
  left: 40%; /* Adjust for elliptical path */
  transform: translateX(-50%);
  background-color: var(--ai-purple);
}

.orbital-body-3 .orbital-dot {
  top: 40%; /* Adjust for elliptical path */
  right: 0;
  transform: translateY(-50%);
  background-color: var(--ai-blue);
}

/* UseCases section styling */
.use-cases-section {
  position: relative;
  overflow: hidden;
}

.use-cases-section::before {
  content: '';
  position: absolute;
  top: -300px;
  right: -300px;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(110, 17, 244, 0.15) 0%, transparent 70%);
  z-index: 0;
}

.use-cases-section::after {
  content: '';
  position: absolute;
  bottom: -200px;
  left: -200px;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, rgba(0, 201, 224, 0.1) 0%, transparent 70%);
  z-index: 0;
}

.animation-card {
  padding: 1.5rem;
  backdrop-filter: blur(16px);
  background: rgba(15, 15, 26, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  height: 100%;
}

.animation-card:hover {
  box-shadow: 0 15px 40px rgba(0, 201, 224, 0.2);
  transform: translateY(-5px);
}

.glass-card.animation-card {
  overflow: hidden;
  position: relative;
}

.glass-card.animation-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(0, 201, 224, 0.3), transparent);
  z-index: 1;
}

.glass-card.animation-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(110, 17, 244, 0.3), transparent);
  z-index: 1;
}

/* Animation container styling */
.animation-container {
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Utility classes for success text */
.text-success {
  color: #00F5A0 !important;
}

/* AI Visualization Container */
.ai-visualization-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  background: var(--ai-dark);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Neural Network SVG Styling */
.neural-network-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Data Signal Animation */
.data-signal {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--ai-teal);
  z-index: 2;
  filter: drop-shadow(0 0 5px var(--ai-teal));
}

/* Three-Body System */
.three-body-system {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  z-index: 3;
}

.orbital-center {
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--ai-teal);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  filter: drop-shadow(0 0 8px var(--ai-teal));
}

.orbit-path {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: center;
}

.orbit-1 {
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbit-2 {
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbit-3 {
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orbital-body-1 {
  background: rgba(0, 201, 224, 0.2);
  border: 1px solid var(--ai-teal);
  box-shadow: 0 0 10px rgba(0, 201, 224, 0.5);
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.orbital-body-2 {
  background: rgba(110, 17, 244, 0.2);
  border: 1px solid var(--ai-purple);
  box-shadow: 0 0 10px rgba(110, 17, 244, 0.5);
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.orbital-body-3 {
  background: rgba(30, 56, 247, 0.2);
  border: 1px solid var(--ai-blue);
  box-shadow: 0 0 10px rgba(30, 56, 247, 0.5);
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Neural Core */
.neural-core {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

/* Machine Learning Particles */
.ml-particle {
  position: absolute;
  border-radius: 50%;
  z-index: 3;
  filter: drop-shadow(0 0 3px currentColor);
}

/* Add responsive styles for the visualization */
@media (max-width: 992px) {
  .ai-visualization-container {
    height: 400px;
  }
  
  .three-body-system {
    width: 150px;
    height: 150px;
  }
  
  .orbit-1 {
    width: 70px;
    height: 70px;
  }
  
  .orbit-2 {
    width: 100px;
    height: 100px;
  }
  
  .orbit-3 {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 576px) {
  .ai-visualization-container {
    height: 300px;
  }
  
  .three-body-system {
    width: 120px;
    height: 120px;
  }
  
  .orbit-1 {
    width: 60px;
    height: 60px;
  }
  
  .orbit-2 {
    width: 80px;
    height: 80px;
  }
  
  .orbit-3 {
    width: 100px;
    height: 100px;
  }
}

.nowrap {
  white-space: nowrap;
}